/*
  breakpoint small => @media screen and (max-width: 640px) {}
  breakpoint not-small => @media screen and (min-width: 641px) {}
  breakpoint medium => @media screen and (min-width: 641px) and (max-width: 1024px) {}
  breakpoint not-large => @media screen and (max-width: 1024px) {}
  breakpoint large => @media screen and (min-width: 1025px) {}
*/

/***************************/
/* Fonts */
/***************************/
@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFProDisplay-Ultralight.woff2") format("woff2"),
    url("../fonts/SFProDisplay-Ultralight.woff") format("woff");
  font-weight: 100;
  /* font-style: normal; */
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFProDisplay-Thin.woff2") format("woff2"), url("../fonts/SFProDisplay-Thin.woff") format("woff");
  font-weight: 300;
  /* font-style: normal; */
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFProDisplay-Regular.woff2") format("woff2"), url("../fonts/SFProDisplay-Regular.woff") format("woff");
  font-weight: normal;
  /* font-style: normal; */
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SFProDisplay-Bold.woff2") format("woff2"), url("../fonts/SFProDisplay-Bold.woff") format("woff");
  font-weight: 600;
  /* font-style: normal; */
}

@font-face {
  font-family: "Adobe Garamond Pro";
  src: url("../fonts/AGaramondPro-Regular.woff2") format("woff2"), url("../fonts/AGaramondPro-Regular.woff") format("woff");
  font-weight: normal;
  /* font-style: normal; */
}

@font-face {
  font-family: "Adobe Garamond Pro";
  src: url("../fonts/AGaramondPro-Bold.woff2") format("woff2"), url("../fonts/AGaramondPro-Bold.woff") format("woff");
  font-weight: 600;
  /* font-style: normal; */
}

.f-text {
  font-family: "Adobe Garamond Pro", Times, "Times New Roman", serif;
}

.f1 {
  font-size: 50px;
}
.f2 {
  font-size: 36px;
}
.f3 {
  font-size: 24px;
}
.f4 {
  font-size: 18px;
}
.f5 {
  font-size: 14px;
}
.f6 {
  font-size: 12px;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .f5-s {
    font-size: 14px;
  }
  .f6-s {
    font-size: 12px;
  }
}

/* breakpoint not small */
@media screen and (min-width: 641px) {
  .f2-ns {
    font-size: 36px;
  }
  .f4-ns {
    font-size: 18px;
  }
}

/* breakpoint medium */
@media screen and (min-width: 641px) and (max-width: 1024px) {
  .f3-m {
    font-size: 24px;
  }
  .f4-m {
    font-size: 18px;
  }
}

/* breakpoint large */
@media screen and (min-width: 1025px) {
  .f1-l {
    font-size: 50px;
  }
  .f2-l {
    font-size: 36px;
  }
  .f3-l {
    font-size: 24px;
  }
  .f4-l {
    font-size: 18px;
  }
}

.fw-100 {
  font-weight: 100;
}
.fw-300 {
  font-weight: 300;
}
.fw-600 {
  font-weight: 600;
}

/* breakpoint not small */
@media screen and (min-width: 641px) {
  .fw-300-ns {
    font-weight: 300;
  }
}

/* breakpoint large */
@media screen and (min-width: 1025px) {
  .fw-100-l {
    font-weight: 100;
  }
}

/***************************/
/* Reset */
/***************************/
*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  min-height: 100%;
  font-family: "SF Pro Display", Helvetica, Arial, sans-serif;
}

button,
input,
textarea {
  border: none;
  background-color: transparent;
}
button:focus,
input:focus,
textarea:focus {
  outline: none;
}

button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: medium;
}

code {
  background-color: #e8e8e8;
  font-size: 1rem;
}

/***************************/
/* Positions */
/***************************/
.fixed {
  position: fixed;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .absolute-s {
    position: absolute;
  }
}

/* breakpoint large */
@media screen and (min-width: 1025px) {
  .relative-l {
    position: relative;
  }
}

/***************************/
/* Coordinates */
/***************************/
.top-0 {
  top: 0;
}
.left-0 {
  left: 0;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .center-elem-x-s {
    left: 50%;
    transform: translateX(-50%);
  }
}

/***************************/
/* Z-Index */
/***************************/
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}

/***************************/
/* Display */
/***************************/
.db {
  display: block;
}
.dib {
  display: inline-block;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .dn-s {
    display: none;
  }
}

/* breakpoint not large */
@media screen and (max-width: 1024px) {
  .dn-nl {
    display: none;
  }
}

/* breakpoint large */
@media screen and (min-width: 1025px) {
  .dn-l {
    display: none;
  }
}

/***************************/
/* Flexbox */
/***************************/
.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.justify-around {
  justify-content: space-around;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}
.flex-3 {
  flex: 3;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .flex-s {
    display: flex;
  }
  .justify-center-s {
    justify-content: center;
  }
  .flex-column-reverse-s {
    flex-direction: column-reverse;
  }
}

/* breakpoint not small */
@media screen and (min-width: 641px) {
  .flex-ns {
    display: flex;
  }
}

/* breakpoint not large */
@media screen and (max-width: 1024px) {
  .flex-column-nl {
    flex-direction: column;
  }

  .flex-column-reverse-nl {
    flex-direction: column-reverse;
  }

  .items-end-nl {
    align-items: flex-end;
  }
}

/* breakpoint large */
@media screen and (min-width: 1025px) {
  .flex-l {
    display: flex;
  }

  .justify-around-l {
    justify-content: space-around;
  }
  .justify-end-l {
    justify-content: flex-end;
  }

  .flex-row-inverse-l {
    flex-direction: row-reverse;
  }
}

/***************************/
/* Widths */
/***************************/
.w-180 {
  width: 180px;
}
.w-p-100 {
  width: 100%;
}
.mxw-1212 {
  max-width: 1212px;
}
.mxw-690 {
  max-width: 690px;
}
.mxw-450 {
  max-width: 450px;
}
.mxw-400 {
  max-width: 400px;
}
.mxw-380 {
  max-width: 380px;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .w-460-s {
    width: 460px;
  }

  .w-p-25-s {
    width: 25%;
  }
  .w-p-50-s {
    width: 50%;
  }
}

/* breakpoint not small */
@media screen and (min-width: 641px) {
  .w-p-40-ns {
    width: 40%;
  }
  .w-p-50-ns {
    width: 50%;
  }
  .w-p-60-ns {
    width: 60%;
  }
}

/* breakpoint not large */
@media screen and (max-width: 1024px) {
  .w-p-100-nl {
    width: 100%;
  }

  .mxw-690-nl {
    max-width: 690px;
  }
  .mxw-450-nl {
    max-width: 450px;
  }
}

/* breakpoint large */
@media screen and (min-width: 1025px) {
  .w-p-50-l {
    width: 50%;
  }
}

/***************************/
/* Heights */
/***************************/
.h-p-100 {
  height: 100%;
}
.vh-100 {
  height: 100vh;
}
.mxh-640 {
  max-height: 640px;
}
.mxh-p-100 {
  max-height: 100%;
}
.h-0 {
  height: 0px;
}
.h-42 {
  height: 42px;
}
.h-64 {
  height: 64px;
}
.h-180 {
  height: 180px;
}
.h-480 {
  height: 480px;
}

/* breakpoint not small */
@media screen and (min-width: 641px) {
  .h-580-ns {
    height: 580px;
  }
}

/* breakpoint medium */
@media screen and (min-width: 641px) and (max-width: 1024px) {
  .h-280-m {
    height: 280px;
  }
}

/* breakpoint not large */
@media screen and (max-width: 1024px) {
  .vh-100-nl {
    height: 100vh;
  }
}

/* breakpoint large */
@media screen and (min-width: 1025px) {
  .h-50-l {
    height: 50px;
  }
  .h-420-l {
    height: 420px;
  }
}

/***************************/
/* Margins */
/***************************/
.mr-s {
  margin-right: 0.5rem;
}
.mr-m {
  margin-right: 1rem;
}
.mr-l {
  margin-right: 2rem;
}

.mb-s {
  margin-bottom: 0.5rem;
}
.mb-m {
  margin-bottom: 1rem;
}
.mb-l {
  margin-bottom: 2rem;
}
.mb-xl {
  margin-bottom: 4rem;
}

.mh-auto {
  margin-right: auto;
  margin-left: auto;
}
.mh-m {
  margin-right: 1rem;
  margin-left: 1rem;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .mb-l-s {
    margin-bottom: 2rem;
  }
  .mb-xl-s {
    margin-bottom: 4rem;
  }
}

/* breakpoint not small */
@media screen and (min-width: 641px) {
  .mr-m-ns {
    margin-right: 1rem;
  }
  .mb-l-ns {
    margin-bottom: 2rem;
  }
  .mb-xl-ns {
    margin-bottom: 4rem;
  }
}

/* breakpoint medium */
@media screen and (min-width: 641px) and (max-width: 1024px) {
  .mb-l-m {
    margin-bottom: 2rem;
  }
  .mb-xl-m {
    margin-bottom: 4rem;
  }
  .mb-xxl-m {
    margin-bottom: 8rem;
  }
}

/* breakpoint not large */
@media screen and (max-width: 1024px) {
  .mb-s-nl {
    margin-bottom: 0.5rem;
  }
  .mb-m-nl {
    margin-bottom: 1rem;
  }
  .mb-l-nl {
    margin-bottom: 2rem;
  }
  .mb-xl-nl {
    margin-bottom: 4rem;
  }

  .mh-m-nl {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

/* breakpoint large */
@media screen and (min-width: 1025px) {
  .mr-s-l {
    margin-right: 0.5rem;
  }
  .mr-m-l {
    margin-right: 1rem;
  }
  .mr-l-l {
    margin-right: 2rem;
  }
  .mb-xl-l {
    margin-bottom: 4rem;
  }
}

/***************************/
/* Paddings */
/***************************/
.pt-l {
  padding-top: 2rem;
}
.pt-xl {
  padding-top: 4rem;
}
.pb-l {
  padding-bottom: 2rem;
}

.pv-l {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.pv-xl {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.ph-s {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.ph-m {
  padding-left: 1rem;
  padding-right: 1rem;
}
.ph-l {
  padding-left: 2rem;
  padding-right: 2rem;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .pt-l-s {
    padding-top: 2rem;
  }

  .pr-m-s {
    padding-right: 1rem;
  }
  .pr-l-s {
    padding-right: 2rem;
  }

  .pb-xl-s {
    padding-bottom: 4rem;
  }

  .ph-m-s {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* breakpoint medium */
@media screen and (min-width: 641px) and (max-width: 1024px) {
  .pr-l-m {
    padding-right: 2rem;
  }
  .pl-l-m {
    padding-left: 2rem;
  }
}

/* breakpoint not large */
@media screen and (max-width: 1024px) {
  .pt-l-nl {
    padding-top: 2rem;
  }
  .pv-l-nl {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

/* breakpoint large */
@media screen and (min-width: 1025px) {
  .pt-xl-l {
    padding-top: 4rem;
  }
  .pr-xl-l {
    padding-right: 4rem;
  }
  .pb-m-l {
    padding-bottom: 1rem;
  }
  .pb-xl-l {
    padding-bottom: 4rem;
  }
  .pv-xl-l {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

/***************************/
/* Borders */
/***************************/
.bd-color-purple {
  border-color: #705b87;
}

.bd-color-blue {
  border-color: #628ca3;
}

.bd-color-green {
  border-color: #5f9e78;
}

.bd-color-yellow {
  border-color: #ccc07a;
}

.bd-color-orange {
  border-color: #e59661;
}

.bd-round {
  border-radius: 50%;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .section-border-s {
    border-bottom: 1px solid #e4e4e4;
  }
}

/***************************/
/* Backgrounds */
/***************************/
.bg-red-gradient {
  background: rgb(128, 71, 70);
  background: linear-gradient(129deg, rgba(128, 71, 70, 1) 0%, rgba(207, 120, 121, 1) 100%);
}

.bg-purple {
  background-color: #705b87;
}

.bg-blue {
  background-color: #628ca3;
}

.bg-green {
  background-color: #5f9e78;
}

.bg-yellow {
  background-color: #ccc07a;
}

.bg-orange {
  background-color: #e59661;
}

.bg-red {
  background-color: #cc7171;
}

.bg-grey-light {
  background-color: #fafafa;
}

/* Sizes */
.bg-cover {
  background-size: cover;
}

/* Positions */
.bg-center {
  background-position: center;
}

@media screen and (max-width: 1024px) {
  .bg-white-nl {
    background-color: #fff;
  }
}

/***************************/
/* Text Colors */
/***************************/
.tc-white {
  color: white;
}
.tc-grey-light {
  color: #666;
}
.tc-grey-dark {
  color: #444;
}
.tc-purple {
  color: #705b87;
}
.tc-blue {
  color: #628ca3;
}
.tc-green {
  color: #5f9e78;
}
.tc-yellow {
  color: #ccc07a;
}
.tc-orange {
  color: #e59661;
}
.tc-red {
  color: #cc7171;
}

/* breakpoint large */
@media screen and (min-width: 1025px) {
  .tc-white-l {
    color: white;
  }
}

/***************************/
/* Text Align */
/***************************/
.tc {
  text-align: center;
}
.tl {
  text-align: left;
}

/* breakpoint not small */
@media screen and (min-width: 641px) {
  .tl-ns {
    text-align: left;
  }
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .tc-s {
    text-align: center;
  }
}

/* breakpoint not large */
@media screen and (max-width: 1024px) {
  .tc-nl {
    text-align: center;
  }
}

/***************************/
/* Vertical Align */
/***************************/
.va-middle {
  vertical-align: middle;
}

/***************************/
/* Overflows */
/***************************/
.of-hidden {
  overflow: hidden;
}
.of-scroll {
  overflow: scroll;
}

/***************************/
/* Line Height */
/***************************/
.lh1 {
  line-height: 1.5em;
}

/***************************/
/* Text Transform */
/***************************/
.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}

/***************************/
/* Transitions */
/***************************/
.td-40 {
  transition-duration: 0.4s;
}
.td-60 {
  transition-duration: 0.6s;
}
.td-80 {
  transition-duration: 0.8s;
}

/***************************/
/* Underlines */
/***************************/
.u-regular-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  border-bottom: 1px solid;
}

.u-default {
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .u-regular-title:after {
    display: none;
  }
}

/***************************/
/* Components */
/***************************/
.btn {
  padding: 0.5rem 2rem;
  box-shadow: 0 2px 3px 1px #ccc;
}

.btn:hover {
  transform: scale(1.1);
}
