.cs-code-logo {
  height: 110px;
}

.tc-react {
  color: #61dafb;
}
.tc-redux {
  color: #764abc;
}
.tc-apollo {
  color: #112b49;
}
.tc-graph {
  color: #e00097;
}
.tc-mongo {
  color: #3e9639;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .cs-code-logo {
    height: 70px;
  }
}

/* breakpoint medium */
@media screen and (min-width: 641px) and (max-width: 1024px) {
  .cs-code-logo {
    height: 90px;
  }
}
