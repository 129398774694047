/* breakpoint medium */
@media screen and (min-width: 641px) and (max-width: 1024px) {
  .wf-image {
    transform: translateY(-40px);
  }
}

/* breakpoint large */
@media screen and (min-width: 1025px) {
  .wf-image {
    transform: translateY(-120px);
  }
}
