.dynamic-screens {
  height: 360px;
}

.dynamic-screen {
  max-width: 300px;
  transform: translate(50px, 50px);
  opacity: 0;
}

.dynamic-screen:nth-last-of-type(1) {
  top: 0;
  left: 0;
  transition: transform 0.8s 0.5s, opacity 0.8s 0.5s;
}

.dynamic-screen:nth-last-of-type(2) {
  top: 170px;
  left: 170px;
  transition: transform 0.8s 1.3s, opacity 0.8s 1.3s;
}

.dynamic-screen:nth-last-of-type(3) {
  top: 50px;
  left: 240px;
  transition: transform 0.8s 0.9s, opacity 0.8s 0.9s;
}

.opened .dynamic-screen {
  transform: translate(0, 0);
  opacity: 1;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .dynamic-screens {
    max-width: 460px;
    height: 300px;
    margin: 0 auto;
  }

  .dynamic-screen:nth-last-of-type(1) {
    top: -20px;
    left: -20px;
  }

  .dynamic-screen:nth-last-of-type(2) {
    top: 130px;
    left: 120px;
  }

  .dynamic-screen:nth-last-of-type(3) {
    top: 10px;
    left: 180px;
  }
}

/* breakpoint medium */
@media screen and (min-width: 641px) and (max-width: 1024px) {
  .dynamic-screens {
    height: 320px;
  }

  .dynamic-screen {
    max-width: 240px;
  }

  .dynamic-screen:nth-last-of-type(2) {
    top: 130px;
    left: 130px;
  }

  .dynamic-screen:nth-last-of-type(3) {
    top: 20px;
    left: 160px;
  }
}
