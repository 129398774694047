.cs-logos-container {
  height: 80px;
  line-height: 80px;
}

.cs-logo-small {
  max-width: 60px;
}

.cs-logo-big {
  max-width: 165px;
}

.cs-logo-icon {
  max-width: 80px;
}

.cs-prev-logos {
  max-width: 890px;
}

.cs-prev-logo {
  height: 62px;
}

/* breakpoint medium */
@media screen and (min-width: 641px) and (max-width: 1024px) {
  .cs-prev-logo {
    height: 50px;
  }
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .cs-logo-icon {
    max-width: 64px;
  }

  .cs-prev-logo {
    height: 42px;
  }
}
