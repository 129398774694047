.cs-related-badges {
  height: 64px;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .cs-related-badges {
    height: 42px;
  }
}
