/***************************/
/* Home Page */
/***************************/
.header-img {
  left: 50%;
  opacity: 0;
  transition-delay: 0.3s;
}

.header-img.active {
  opacity: 1;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .cover {
    padding-top: 6rem;
  }

  .header-img {
    width: 210px;
    top: 100px;
    transform: translateX(-50%);
  }

  .header-img.active {
    top: 20px;
  }
}

/* breakpoint not-small */
@media screen and (min-width: 641px) {
  .header-img {
    width: 320px;
    bottom: -180px;
    transform: translateX(-20%);
  }

  .header-img.active {
    bottom: -100px;
  }
}

/***************************/
/* Case Study Page */
/***************************/
.cs-cover__title {
  font-size: 60px;
}

.cs-cover__title span {
  font-size: 42px;
}

.cs-cover__title span:before,
.cs-cover__title span:after {
  content: "•";
  display: inline-block;
}

.cs-cover__image {
  opacity: 0;
  transition-delay: 0.4s;
}

.cs-cover__image.active {
  opacity: 1;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .cs-cover__title {
    font-size: 50px;
  }

  .cs-cover__title span {
    font-size: 35px;
  }

  .cs-cover__image {
    bottom: 10px;
    left: 50%;
    width: 500px;
    transform: translate(0, 50%);
  }

  .cs-cover__image.active {
    bottom: 10px;
    left: calc(50% -250px);
    width: 500px;
    transform: translate(-50%, 0);
  }
}

/* breakpoint medium */
@media screen and (min-width: 641px) and (max-width: 1024px) {
  .cs-cover__image {
    top: 10px;
    left: 90px;
    width: 1000px;
  }

  .cs-cover__image.active {
    top: -20px;
    left: 60px;
  }
}

/* breakpoint large */
@media screen and (min-width: 1025px) {
  .cs-cover__image {
    top: -90px;
    left: 190px;
  }

  .cs-cover__image.active {
    top: -120px;
    left: 160px;
  }
}
