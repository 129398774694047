.badges {
  width: 100%;
  height: 260px;
}

.badges__item {
  height: 260px;
}

.badges__img {
  width: 100%;
  max-width: 400px;
}

.badges-nav:after {
  content: "";
  position: absolute;
  top: 16px;
  z-index: -1;
  height: 90%;
  width: 1px;
  background-color: #efefef;
}

.badges-nav__item {
  height: 42px;
  width: 42px;
}

.badges-nav__item .active {
  transform: scale(2.5);
}

.badges-nav__link {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.badges-nav__icon {
  width: 8px;
}

.badges-nav__purple {
  background-color: #705b87;
}

.badges-nav__blue {
  background-color: #628ca3;
}

.badges-nav__green {
  background-color: #5f9e78;
}

.badges-nav__yellow {
  background-color: #ccc07a;
}

.badges-nav__orange {
  background-color: #e59661;
}

.badges-nav__red {
  background-color: #cc7171;
}

.badges-nav__red-dark {
  background-color: #804746;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .badges-nav {
    bottom: -320px;
    left: 50%;
    transform: rotate(-90deg) translateY(-50%);
    transform-origin: top left;
  }

  .badges-nav__item {
    transform: rotate(90deg);
  }
}

/* breakpoint not small */
@media screen and (min-width: 641px) {
  .badges-nav {
    top: -16px;
    left: 92%;
  }
}

/* breakpoint large */
@media screen and (min-width: 1025px) {
  .badges {
    width: calc(100% + 16px);
    padding-right: 16px;
  }
}
