.logo {
  height: 68px;
}

/*** Nav menu ***/

/* breakpoint not large */
@media screen and (max-width: 1024px) {
  .nav-menu {
    transform: translateX(100%);
  }
  .nav-menu--opened {
    transform: translateX(0);
  }
}

/*** Nav link hovers ***/

/* breakpoint large */
@media screen and (min-width: 1025px) {
  .nav-link:after {
    content: attr(data-hover);
    position: absolute;
    top: 0;
    left: 50%;
    width: 110%;
    transform: translate(-50%, 4px);
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: rgba(255, 255, 255, 0);
    transition: color 0.3s 0.05s, font-size 0.3s 0.05s, transform 0.3s;
  }

  .nav-link.active,
  .nav-link:hover {
    color: rgba(255, 255, 255, 0);
  }

  .nav-link.active:after,
  .nav-link:hover:after {
    transform: translate(-50%, -2px);
    color: #fff;
    font-size: 18px;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);
    transition: color 0.3s, font-size 0.3s, transform 0.3s;
  }
}

/* breakpoint not large */
@media screen and (max-width: 1024px) {
  .nav-link.active {
    color: #cc7171;
    font-weight: 600;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
}

/*** Menu Icon ***/
.menu-icon {
  width: 30px;
  height: 25px;
  transition: transform 0.3s 0.2s;
}

.menu-icon:after,
.menu-icon:before,
.menu-icon span {
  position: absolute;
  right: 0;
  display: block;
  height: 4px;
  border-radius: 2px;
}

.menu-icon:after,
.menu-icon:before {
  content: "";
}

.menu-icon span {
  top: 0;
  width: 30px;
  transition-duration: 0.2s;
  transition-delay: 0.3s;
}

.menu-icon:before {
  top: 10px;
  width: 26px;
  transition-delay: 0.2s;
}

.menu-icon:after {
  top: 20px;
  width: 22px;
  transition-duration: 0.2s;
  transition-delay: 0.3s;
}

.menu-icon.close {
  transform: rotate(180deg);
  transition: transform 0.3s 0.05s;
}

.menu-icon.close span {
  transform: rotate(-45deg);
  width: 22px;
  top: 3px;
  right: 6px;
  transition: background-color 0s 0s, transform 0.2s 0s, width 0.2s 0s, top 0.2s 0s, right 0.2s 0s;
}

.menu-icon.close:before {
  transition: background-color 0s 0s;
}

.menu-icon.close:after {
  transform: rotate(45deg);
  top: 17px;
  right: 6px;
  transition: background-color 0s 0s, transform 0.2s 0s, top 0.2s 0s, right 0.2s 0s;
}

.menu-icon--white:after,
.menu-icon--white:before,
.menu-icon--white span {
  background-color: #fff;
}

.menu-icon--dark:after,
.menu-icon--dark:before,
.menu-icon--dark span {
  background-color: #cc7171;
}
