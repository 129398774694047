.cs-cta-logo {
  width: 90px;
}

.cs-cta-screens {
  height: 360px;
}

.cs-cta-screens:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 640px;
  height: 50px;
  transform: translateX(-50%);
  background: radial-gradient(ellipse, rgba(179, 179, 179, 0.4) 0%, rgba(179, 179, 179, 0) 50%);
  transition: opacity 3s 0.6s;
  border-radius: 50%;
  overflow: hidden;
  opacity: 0;
}

.cs-cta-screens li {
  transform: translateY(80px);
  opacity: 0;
}

.cs-cta-screens li:nth-last-of-type(1),
.cs-cta-screens li:nth-last-of-type(2) {
  top: 12px;
  width: 146px;
  box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.2);
}

.cs-cta-screens li:nth-last-of-type(1) {
  right: 0;
  transition: transform 0.8s 0.6s, opacity 0.8s 0.6s;
}

.cs-cta-screens li:nth-last-of-type(2) {
  transition: transform 0.8s 1.4s, opacity 0.8s 1.4s;
}

.cs-cta-screens li:nth-last-of-type(3) {
  top: 0;
  left: calc(50% - 80px);
  z-index: 2;
  width: 160px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.8s 2.2s, opacity 0.8s 2.2s;
}

.opened .cs-cta-screens li {
  transform: translate(0, 0);
  opacity: 1;
}

.opened .cs-cta-screens:after {
  opacity: 1;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .cs-cta-screens {
    height: 320px;
  }
  .cs-cta-screens:after {
    width: 420px;
    height: 40px;
  }

  .cs-cta-screens li:nth-last-of-type(1),
  .cs-cta-screens li:nth-last-of-type(2) {
    width: 120px;
  }
  .cs-cta-screens li:nth-last-of-type(3) {
    width: 140px;
    left: calc(50% - 70px);
  }
}
