@keyframes points-feedback {
  0% {
    opacity: 1;
    top: 90px;
  }
  100% {
    opacity: 0;
    top: 70px;
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

.icon-close {
  top: 2rem;
  right: 2rem;
  width: 24px;
  height: 24px;
}

.icon-close:after,
.icon-close:before {
  content: "";
  position: absolute;
  top: 10px;
  left: -4px;
  width: 32px;
  border-bottom: 1px solid #666;
}

.icon-close:after {
  transform: rotate(45deg);
}

.icon-close:before {
  transform: rotate(-45deg);
}

.modal-container {
  background-color: #fff;
}

.modal-pagination {
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
}

.op-btn {
  width: 300px;
  height: 60px;
  border-width: 1px;
  border-style: solid;
}

.op-btn:hover {
  box-shadow: 0 3px 5px #ccc;
  transform: translateY(-3px);
}

.op-btn__label {
  line-height: 58px;
}

.op-btn__icon {
  width: 60px;
  height: 58px;
  border-left-width: 1px;
  border-left-style: solid;
}

.op-btn__level--icon {
  height: 58px;
}

.op-btn__level--icon img {
  width: 60px;
  margin-right: 0.5rem;
}

.timer {
  height: 6px;
  width: 400px;
  background-color: #efefef;
}

.bar-active {
  width: 0;
  transition-timing-function: linear;
}

.game-input {
  border-bottom: 1px solid #666;
  line-height: 48px;
}

.points-feedback {
  top: 90px;
  left: 110px;
  opacity: 0;
}

.points-feedback.active {
  animation: points-feedback 1s 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.resume-box {
  margin-right: 2rem;
  margin-left: 2rem;
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .modal-container {
    height: 100vh;
  }

  .timer {
    height: 4px;
    width: 280px;
  }

  .icon-close {
    top: 1rem;
    right: 1rem;
  }
}

/* breakpoint not small */
@media screen and (min-width: 641px) {
  .modal-container {
    min-height: 640px;
  }

  .timer {
    height: 6px;
    width: 400px;
  }

  .resume-box {
    background-color: #efefef;
  }

  .resume-box > div:first-child {
    border-right: 1px solid #969696;
  }
}
