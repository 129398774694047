.color-ball {
  width: 5vw;
  max-width: 64px;
  height: 5vw;
  max-height: 64px;
  box-shadow: 0 2px 3px 1px #b1b1b1;
}

/* breakpoint medium */
@media screen and (min-width: 641px) and (max-width: 1024px) {
  .color-ball {
    width: 64px;
    height: 64px;
  }
}

/* breakpoint small */
@media screen and (max-width: 640px) {
  .color-ball {
    width: 42px;
    height: 42px;
  }
}
